.tableCell{
    border: 1px solid black;
}

.highlightedSankalp{
    color: #00e676;
    font-weight: 500;
}

.unhighlightedSankalp{
    color: #d9534f;
}